<template>
  <ActionModal
    v-if="isModalShown"
    :width="'480px'"
    :height="'200px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Stopped</div>
      <div class="close-modal-btn" @click="$emit('hideModal')"></div>
    </div>
    <div class="modal-subtitle">You’ve successfully stopped subscription for {{stoppedUser}}</div>
    <div class="modal-content">
      <ActionButton
        :width="'150px'"
        :height="'44px'"
        :text="'Ok'"
        @click="$emit('agreeStopped')"
      />
    </div>
  </ActionModal>
</template>

<script>
import ActionModal from '../../../common/ActionModal.vue'
import ActionButton from '../../../common/ActionButton.vue'
export default {
  props: [
    'isModalShown',
    'isModalLoading',
    'modalError',
    'stoppedUser'
  ],
  components: {
    ActionModal,
    ActionButton,
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}
.modal-subtitle {
  font-size: $fontSizeMinified;
  color: $fontColorGray;
  display: flex;
  width: 83%;
  align-items: flex-start !important;
}
.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .action-button-delete {
    background-color: white;
    border: 1px solid $grayBorderColor;
    color: black;
  }
}

@media (max-width: 420px) {
  .action-button {
    width: 100px !important;
  }
}
</style>
