<template>
  <MainHeader>
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title">Prospects</div>
          <div class="current-date">{{getCurrentDate()}}</div>
        </div>
      </div>
      <UserInfoHeaderSection
        :username="currentUser.full_name"
      />
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <UndoRedoBtns
          :isUndoStackActive="isUndoStackActive"
          :isRedoStackActive="isRedoStackActive"
          @undo="Undo()"
          @redo="Redo()"
        />
        <SearchField
          @search ="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
        <MoreActionsBtn
          :width="'44px'"
          :height="'44px'"
          :class="{'active-more-action-btn': actionActive}"
          :isActive="isActionMenuShown"
          @click="openHeaderActionMenu"
        />
        <ActionMenu
          :width="'280px'"
          :height="'98px'"
          :top="'50px'"
          :isActionMenuShown="isActionMenuShown"
          @closeActionMenu="closeHeaderActionMenu"
        >
          <ActionMenuItem
            v-for="(action, index) in Object.values(menuActions)"
            :key="index"
            :text="action"
            @actionMenuItemClick="handleActionMenuClick(action)"
          />
        </ActionMenu>
      </div>
      <ActionButton
        :width="'210px'"
        :class='{"inactive-btn":!isSubscriptionActive}'
        :height="'44px'"
        :text="'+ Add new prospect'"
        @click="showModal"
      />
      <ProspectsActionModal
        :isModalShown="isModalShown"
        :isModalLoading="isModalLoading"
        :modalError="modalError"
        :newProspectName="newProspectName"
        :newProspectCompany="newProspectCompany"
        :newProspectStatus="newProspectStatus"
        @hideModal="hideModal"
        @setNewProspectName="setNewProspectName"
        @setNewProspectCompany="setNewProspectCompany"
        @setNewProspectStatus="setNewProspectStatus"
        @createProspect="handleNewProspectCreation"
      />
    </div>
  </MainHeader>
  <MainCard>
    <CardTableHeader
      :isCheckboxShown="true"
      :isCompanyShown="true"
      :selectedRows="selectedRows"
      :prospectStatuses="prospectStatus"
      :isSelectAllCheckbox='isSelectAllCheckbox'
      :order="order"
      @checkboxSelect="selectAllRows"
      @removeSelection="removeRowsSelection"
      @setOrder="setOrder"
    />
    <DividerLine />
    <div class="prospects-list" v-if="currentPage.length && !errorMessage && !isLoading">
      <draggable
        v-model="currentPage"
        handle=".drag-handle"
        ghost-class="ghost"
        :disabled="order !== '' || isDragDisabled"
        @end="handleDragEnd"
        item-key="id"
      >
        <template #item="{element}">
          <CardRow
            :key="element.id"
            :id="element.id"
            :order="order"
            :isCheckboxShown="true"
            :isCompanyEditable="'true'"
            :isNameEditable="'true'"
            :name="element.name"
            :company="element.company"
            :currentItemStatus="element.status"
            :statuses="Object.values(prospectStatus)"
            :selectedRows="selectedRows"
            :isStatusShown="'true'"
            :menuActions="Object.values(menuActions)"
            :actionMenuWidth="'282px'"
            :actionMenuHeight="'98px'"
            :actionMenuTop="'45px'"
            :isSubscriptionActive="isSubscriptionActive"
            @updateName="handleProspectUpdate({ name: $event }, element)"
            @updateCompany="handleProspectUpdate({ company: $event }, element)"
            @updateStatus="handleProspectUpdate({ status: $event }, element)"
            @checkboxSelect="selectOneRow(element.id)"
            @removeSelection="removeOneRowSelection(element.id)"
            @actionMenuItemClick="handleActionMenuClick($event, element.id)"
          />
        </template>
      </draggable>
    </div>
    <div class="error-message" v-if="errorMessage">
      {{errorMessage}}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
  </MainCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

import MainHeader from '../../common/header/MainHeader.vue'
import UserInfoHeaderSection from '../../common/header/UserInfoHeaderSection.vue'
import MainCard from '../../common/mainContent/MainCard.vue'
import CardTableHeader from '../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../common/DividerLine.vue'
import CardRow from '../../common/mainContent/CardRow.vue'
import CardFooter from '../../common/mainContent/CardFooter.vue'
import UndoRedoBtns from '../../common/header/UndoRedoBtns.vue'
import SearchField from '../../common/header/SearchField.vue'
import MoreActionsBtn from '../../common/MoreActionsBtn.vue'
import ActionButton from '../../common/ActionButton.vue'
import ProspectsActionModal from '../../common/ProspectsActionModal.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem.vue'
import Spinner from '../../common/Spinner.vue'

import { getCurrentDate } from '../../../utils/helpers'
import { ProspectStatus, UserSubscriptionStatus } from '../../../utils/constants'

export default {
  components: {
    draggable,
    MainHeader,
    UserInfoHeaderSection,
    MainCard,
    CardTableHeader,
    DividerLine,
    CardRow,
    CardFooter,
    UndoRedoBtns,
    SearchField,
    MoreActionsBtn,
    ActionButton,
    ProspectsActionModal,
    ActionMenu,
    ActionMenuItem,
    Spinner,
  },
  emits: ['openSidebar'],
  data() {
    return {
      isLoading: true,
      pageSize: 'All',
      currentPageNumber: 1,
      searchTerm: '',
      order: '',
      isDragDisabled: false,
      selectedRows: [],
      errorMessage: '',
      isActionMenuShown: false,
      isModalShown: false,
      isModalLoading: false,
      modalError: '',
      prospectStatus: ProspectStatus,
      newProspectName: '',
      newProspectCompany: '',
      newProspectStatus: 'Target',
      isSelectAllCheckbox: false,
      actionActive: false,
      menuActions: {
        MOVE_TO_CLIENT: 'Move to client',
        ARCHIVE: 'Archive',
      },
      activeSubscriptionStatuses: [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus["Granted Until"],
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus.Canceled,
      ],
      RowsPerPage: ['All', 10, 20, 50]
    }
  },
  async created() {
    await this.getFirstPage()
  },
  computed: {
    ...mapState([
      'currentUser',
      'prospects',
      'isUndoStack',
      'isRedoStack',
    ]),
    isSubscriptionActive() {
      return this.activeSubscriptionStatuses.includes(this.currentUser.subscription.status)
    },
    prospectsList() {
      return this.prospects.prospectsList
    },
    currentPage: {
      get() {
        if (this.pageSize === 'All') return this.prospectsList

        const start = this.currentPageNumber === 1
          ? 0
          : this.currentPageNumber*this.pageSize - this.pageSize

        const stop = this.currentPageNumber === 1
          ? this.pageSize
          : this.currentPageNumber * this.pageSize

        const page = this.prospectsList.slice(start, stop)

        return page
      },
      set(value) {},
    },
    isUndoStackActive(){
      return this.isUndoStack
    },
    isRedoStackActive(){
      return this.isRedoStack
    },
    totalItemsCount() {
      return this.prospects.prospectsCount
    },
    lastId() {
      return this.currentPage.length
        ? this.currentPage.pop().id
        : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    toItems() {
      if (this.pageSize === 'All') return this.prospectsList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
  },
  watch: {
    modalError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000);
      }
    },
    selectedRows(newValue, oldValue) {
      if (newValue.length >= 3) {
        this.actionActive = true
      } else {
        this.actionActive = false
      }
    },
    async order() {
      this.isLoading = true
      this.currentPageNumber = 1

      await this.clearProspects()
      await this.getFirstPage()
    },
  },
  methods: {
    ...mapActions([
      'getNextProspectsPage',
      'updateProspectsOrder',
      'createProspect',
      'updateProspect',
      'moveProspectsToClients',
      'archiveProspects',
      'clearProspects',
      'addDeals',
      'searchProspect',
      'handelUndoRedoClick',
    ]),
    getCurrentDate,
    handleGeneralError(error) {
      console.log('==== error while moving prospect to client: ', error);
      this.errorMessage = 'Something went wrong, please try again'
    },
    async getFirstPage() {
      try {
        await this.getNextProspectsPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          order: this.order,
        })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      await this.clearProspects()
      await this.getFirstPage()
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue
      this.currentPageNumber = 1
      const payload = {
        order: this.order,
        searchTerm: searchValue,
        pageSize: this.pageSize,
        pageNumber: this.currentPageNumber,
      }

      try {
        await this.searchProspect(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async Undo() {
      this.currentPageNumber = 1
      await this.handelUndoRedoClick('undoStack')
      await this.clearProspects()
      await this.getFirstPage()
    },
    async Redo() {
      this.currentPageNumber = 1
      await this.handelUndoRedoClick('redoStack')
      await this.clearProspects()
      await this.getFirstPage()
    },
    setOrder(evt) {
      if (this.isLoading) return
      this.order = evt
    },
    async handleDragEnd(evt) {
      this.isDragDisabled = true

      const { oldIndex, newIndex } = evt

      if (oldIndex === newIndex) return

      const elementId = this.currentPage[oldIndex].id

      try {
        await this.updateProspectsOrder({
          elementId,
          oldLocalIndex: oldIndex,
          newLocalIndex: newIndex,
        })
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isDragDisabled = false
      }
    },
    async getNextPage() {
      if (this.currentPageNumber !== 0 && (this.toItems === this.totalItemsCount) || this.isLoading) return

      this.currentPageNumber += 1
      this.selectedRows = []

      if (this.currentPage.length) return

      this.isLoading = true

      try {
        await this.getNextProspectsPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          searchTerm: this.searchTerm,
          order: this.order,
        })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.selectedRows = []
      this.currentPageNumber -= 1
    },
    openHeaderActionMenu() {
      if (!this.isSubscriptionActive) return
      this.isActionMenuShown = true
      this.actionActive = false
    },
    closeHeaderActionMenu() {
      this.isActionMenuShown = false
    },
    showModal() {
      if (!this.isSubscriptionActive) return
      this.isModalShown = true
    },
    hideModal() {
      this.modalError = ''
      this.isModalShown = false
    },
    setNewProspectName(newProspectName) {
      this.newProspectName = newProspectName
    },
    setNewProspectCompany(newProspectCompany) {
      this.newProspectCompany = newProspectCompany
    },
    setNewProspectStatus(statusName) {
      this.newProspectStatus = statusName
    },
    async handleNewProspectCreation() {
      if (!this.newProspectName || !this.newProspectCompany) {
        this.modalError = 'Prospect name and company are required'
        return
      }

      const payload = {
        new_prospect: {
          name: this.newProspectName,
          company: this.newProspectCompany,
          status: this.prospectStatus[this.newProspectStatus]
        },
      }

      try {
        this.isModalLoading = true

        await this.createProspect(payload)

        this.isModalShown = false
        this.order = ''
        this.newProspectName = ''
        this.newProspectCompany = ''
      } catch (error) {
        console.log('==== error while creating prospect: ', error);
        this.modalError = 'Something went wrong, please try again'
      } finally {
        this.isModalLoading = false
      }
    },
    selectAllRows() {
      const ids = this.currentPage.map(item => item.id)
      this.selectedRows = ids
    },
    removeRowsSelection() {
      this.selectedRows = []
    },
    selectOneRow(rowId) {
      this.selectedRows.push(rowId)
      if (this.selectedRows.length >= 1) {
        this.actionActive = true
      }
      if (this.selectedRows.length === this.currentPage.length){
        this.isSelectAllCheckbox = true
      }
    },
    removeOneRowSelection(rowId) {
      const rowIndex = this.selectedRows.indexOf(rowId)
      this.selectedRows.splice(rowIndex, 1)
      if (this.selectedRows.length < 1) {
        this.actionActive = false
      }
      if (this.selectedRows.length < this.currentPage.length) {
        this.isSelectAllCheckbox = false
      }
    },
    handleProspectUpdate(partialProspectUpdate, prospect) {
      if (!this.isSubscriptionActive) return
      const updatedProspect = { ...prospect, ...partialProspectUpdate }
      this.updateProspect(updatedProspect)
    },
    async handleActionMenuClick(action, prospectId=null) {
      this.isActionMenuShown = false
      this.isLoading = true

      const prospectIds = prospectId ? [prospectId] : this.selectedRows

      if (!prospectIds.length) {
        this.isLoading = false
        return;
      }

      try {
        if (action === this.menuActions.MOVE_TO_CLIENT) {
          await this.moveProspectsToClients(prospectIds)
        }

        if (action === this.menuActions.ARCHIVE) {
          await this.archiveProspects(prospectIds)
        }

        this.currentPageNumber = 1

        await this.clearProspects()
        await this.getNextProspectsPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          order: this.order,
        })

        this.removeRowsSelection()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
  async beforeUnmount() {
    await this.clearProspects()
  },
}
</script>

<style lang="scss" scoped>
  @import '../../../assets/styleVars.scss';

  .inactive-btn {
    background-color: #9C9EA6 !important;
    cursor: not-allowed;
  }

  .active-more-action-btn {
    background-color: $appActionColor;
    background-image: url('../../../assets/icons/more-white-icon.svg');
  }

  .header-row {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: $fontColorBlack;

    .sidebar-btn {
      width: 0;
      height: 0;
      position: absolute;
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../../assets/icons/hamburger-icon.svg');
    }

    .title-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .title-container {
        display: flex;
        flex-direction: column;

        .title {
          font-size: $fontSizeTitle;
        }

        .current-date {
          font-size: $fontSizeMinified;
          color: $fontColorGray;
        }
      }
    }

    .left-side-actions {
      position: relative;
      display: flex
    }
  }

  @media (max-width: 1024px) {
    .header-row {
      align-items: center;

      .sidebar-btn {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }

      .action-button {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 420px) {

    .card-footer {
      width: 980px;
    }
    .card-footer:deep(.rows-per-page) {
      margin-left: 340px !important;
    }

    .prospects-list {
      overflow-y:visible;

      .card-row {
        .editable-field:deep(.value-container) {
          width: 150px;
          min-width: 150px !important;
        }
      }
    }

    .divider-line {
      width: 980px;
    }

    .card-table-header {
      width: calc(100% - 20px) !important;
    }

    .action-button {
      width: 78px !important;
      font-size: $fontSizeActionButtonMinified;
      padding-left: 5px;
    }
  }
</style>

<style lang="scss">
  @import '../../../assets/styleVars.scss';

  .prospects-list {
    width: 100%;
    height: calc(100% - 104px);
    overflow-y: scroll;

    .ghost {
      opacity: 0.9;
      background: $appActionColor;
    }
  }

  .error-message {
    width: 100%;
    height: calc(100% - 104px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
  }
</style>
