<template>
  <div class="member-row">
    <div class="member-row-text-field">
      {{ subject_name }}
    </div>
    <div class="member-row-text-field">
      {{ subject_company }}
    </div>
    <div class="member-row-text-field">
      {{ formattedValue }}
    </div>
    <div class="switcher-container" v-if="is_closed !== ''">
      <CustomSwitcher :isSwitcherChecked="is_closed" :isLocked="true" />
    </div>
  </div>
</template>

<script>
import { UserSubscriptionStatus } from '@/utils/constants'
import CustomSwitcher from '@/components/common/mainContent/CustomSwitcher.vue'

export default {
  props: ['id', 'value', 'is_closed', 'subject_name', 'subject_company'],
  components: {
    CustomSwitcher,
  },
  computed: {
    formattedValue() {
      return this.value
        ? '$' + this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : this.value
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styleVars.scss';

.member-row {
  width: calc(100% - 80px);
  height: 44px;
  padding: 0 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;

  .member-row-text-field {
    width: 150px;
    min-width: 150px;
    height: 20px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }
  .switcher-container {
    width: 150px;
    min-width: 150px;
    //margin-left: 30px;
    display: flex;
    margin-left: auto;
    margin-right: 40px;
    align-items: center;
    justify-content: center;
  }

  .action-btn-container {
    width: 42px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .more-actions-btn {
      border: none;
    }

    .more-actions-btn-active-state {
      border: 1px solid $grayBorderColor;
    }
  }
}
</style>
