<template>
  <ActionModal
    v-if="isModalShown"
    :width="'480px'"
    :height="'300px'"
    :isModalShown="isModalShown"
    :isLodaing="isModalLodaing"
  >
  <div class="modal-header">
    <div class="modal-title">Renew Subscription</div>
    <div class="close-modal-btn" @click="$emit('hideModal')"></div>
  </div>
  <div class="modal-subtitle">Set your subscription expiration date</div>
  <div class="modal-error">{{modalError}}</div>
  <div class="modal-content">
    <CommonInputField
      :width="'100%'"
      :height="'68px'"
      :inputHeight="'44px'"
      :lable="'Expiration Date'"
      :placeholder="'MM/DD/YYYY'"
      :value=expirationDate
      @updateValue="''"
    />
    <ActionButton
      :width="'400px'"
      :height="'54px'"
      :text="'Save'"
      @click="$emit('renewSubscriptions')"
    />
  </div>
  </ActionModal>
</template>

<script>
import ActionModal from '../../../common/ActionModal.vue'
import ActionButton from '../../../common/ActionButton.vue'
import CommonInputField from '../../../common/CommonInputField.vue'
export default {
  props: [
    'isModalShown',
    'isModalLoading',
    'modalError',
    'newSubscriptionDate',
  ],
  components: {
    ActionButton,
    ActionModal,
    CommonInputField,
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }
  
  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}
.modal-subtitle {
  font-size: $fontSizeMinified;
  color: $fontColorGray;
  display: flex;
  width: 83%;
  align-items: flex-start !important;
}
.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media (max-width: 420px) {
  .action-button {
    width: 275px !important;
  }
}
</style>