<template>
  <div class="attach-table-header">
    <div class="attach-header-item">Name</div>
    <div class="attach-header-item"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.attach-table-header {
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  font-size: $fontSizeMinified;
  color: $fontColorGray;

  .attach-header-item {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 170px;
  }
}
</style>
