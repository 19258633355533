<template>
  <ActionModal
    v-if="isModalShown"
    :width="'480px'"
    :height="'675px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Create account</div>
      <div class="close-modal-btn" @click="$emit('hideModal')"></div>
    </div>
    <div class="modal-error">{{modalError}}</div>
    <div class="modal-content">
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Full Name'"
        :placeholder="'Enter Name'"
        :value=newClientName
        @updateValue="$emit('setNewClientName',$event)"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :placeholder="'Enter Email'"
        :lable="'Email'"
        :value=newClientEmail
        @updateValue="$emit('setNewClientEmail', $event)"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Company'"
        :placeholder="'Enter member\'s company'"
        :value="newMemberCompany"
        @updateValue="$emit('setNewMemberCompany', $event)"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Address'"
        :placeholder="'Enter member\'s address'"
        :value="newMemberAddress"
        @updateValue="$emit('setNewMemberAddress', $event)"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :placeholder="'Enter Phone'"
        :lable="'Phone'"
        :value=newClientPhone
        @updateValue="$emit('setNewClientPhone',$event)"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :placeholder="'Enter Password'"
        :lable="'Password'"
        :value=newClientPassword
        @updateValue="$emit('setNewClientPassword',$event)"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :placeholder="'Repeat Password'"
        :lable="'Confirm new Password'"
        :value=confirmNewPassword
        @updateValue="$emit('setConfirmNewPassword',$event)"
      />
      <ActionButton
        :width="'400px'"
        :height="'45px'"
        :minHeight="'45px'"
        :text="'Save'"
        @click="$emit('createAccount')"
      />
    </div>
  </ActionModal>
</template>

<script>
import ActionModal from '../../../common/ActionModal.vue'
import ActionButton from '../../../common/ActionButton.vue'
import CommonInputField from '../../../common/CommonInputField.vue'
export default {
  props: [
    'isModalShown',
    'isModalLoading',
    'modalError',
    'newClientName',
    'newClientEmail',
    'newMemberCompany',
    'newMemberAddress',
    'newClientPhone',
    'newClientPassword',
    'confirmNewPassword',
  ],
  components: {
    ActionModal,
    ActionButton,
    CommonInputField,
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

:deep(.action-modal) {
  overflow-y: scroll !important;
}

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .common-input-field-container {
    margin-top: 20px;
  }
}

@media (max-width: 420px) {
  .action-button {
    width: 275px !important;
  }
}
</style>
