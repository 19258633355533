<template>
  <ActionModal
    v-if="isModalShown"
    :width="'480px'"
    :height="'675px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Edit member</div>
      <div class="close-modal-btn" @click="$emit('hideModal')"></div>
    </div>
    <div class="modal-error">{{modalError}}</div>
    <div class="modal-content">
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Full Name'"
        :placeholder="'Enter name'"
        :value="localMemberToEdit.full_name"
        @updateValue="localMemberToEdit.full_name = $event"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Email'"
        :placeholder="'Enter email address'"
        :value="localMemberToEdit.email"
        @updateValue="localMemberToEdit.email = $event"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Company'"
        :placeholder="'Enter member\'s company'"
        :value="localMemberToEdit.represented_company"
        @updateValue="localMemberToEdit.represented_company = $event"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Address'"
        :placeholder="'Enter member\'s address'"
        :value="localMemberToEdit.address"
        @updateValue="localMemberToEdit.address = $event"
      />
      <CommonInputField
        :width="'100%'"
        :height="'68px'"
        :inputHeight="'44px'"
        :lable="'Phone'"
        :placeholder="'Enter member\'s phone'"
        :value="localMemberToEdit.phone"
        @updateValue="localMemberToEdit.phone = $event"
      />
      <div class="input-container password-container">
        <div class="field-label">Password</div>
        <input
          :type="passwordFieldType"
          :placeholder="'Enter new password'"
          class="input-field"
          :value="password"
          @input="handlePasswordInput('password', $event.target.value)"
        />
        <div
          class="password-visibility-btn"
          :class="{
            'password-visible-icon': passwordFieldType === passwordFieldTypes.PASSWORD,
            'password-invisible-icon': passwordFieldType === passwordFieldTypes.TEXT,
          }"
          @click="switchPasswordVisibility"
        >
        </div>
      </div>
      <div class="input-container password-container">
        <div class="field-label">Confirm password</div>
        <input
          :type="passwordFieldType"
          :placeholder="'Confirm new password'"
          class="input-field"
          :value="passwordConfirmation"
          @input="handlePasswordInput('passwordConfirmation', $event.target.value)"
        />
        <div
          class="password-visibility-btn"
          :class="{
            'password-visible-icon': passwordFieldType === passwordFieldTypes.PASSWORD,
            'password-invisible-icon': passwordFieldType === passwordFieldTypes.TEXT,
          }"
          @click="switchPasswordVisibility"
        >
        </div>
      </div>
      <ActionButton
        :width="'400px'"
        :height="'45px'"
        :minHeight="'45px'"
        :text="'Save'"
        @click="handleMemberUpdate"
      />
    </div>
  </ActionModal>
</template>

<script>
import { mapActions } from 'vuex'

import ActionModal from '../../../common/ActionModal.vue'
import ActionButton from '../../../common/ActionButton.vue'
import CommonInputField from '../../../common/CommonInputField.vue'

export default {
  props: [
    'isModalShown',
    'memberToEdit',
  ],
  components: {
    ActionModal,
    ActionButton,
    CommonInputField,
  },
  data() {
    return {
      modalError: '',
      isModalLoading: false,
      localMemberToEdit: {},
      passwordFieldTypes: {
        PASSWORD: 'password',
        TEXT: 'text',
      },
      passwordFieldType: 'password',
      password: '',
      passwordConfirmation: '',
    }
  },
  watch: {
    memberToEdit(newValue) {
      this.localMemberToEdit = JSON.parse(JSON.stringify(newValue))
    },
  },
  methods: {
    ...mapActions([
      'updateMember',
    ]),
    switchPasswordVisibility() {
      if (this.passwordFieldType === this.passwordFieldTypes.PASSWORD) {
        this.passwordFieldType = this.passwordFieldTypes.TEXT
      } else {
        this.passwordFieldType = this.passwordFieldTypes.PASSWORD
      }
    },
    handlePasswordInput(fieldType, newValue) {
      const value = newValue.replaceAll(' ', '')

      if (fieldType === 'password') {
        this.password = value
      } else if (fieldType === 'passwordConfirmation') {
        this.passwordConfirmation = value
      }
    },
    validateEmail() {
      this.localMemberToEdit.email = this.localMemberToEdit.email.replaceAll(' ', '')

      const emailPattern = new RegExp(/^.+@.+\..+$/)

      return emailPattern.test(this.localMemberToEdit.email)
    },
    validatePassword() {
      return this.password.length >= 8 && (this.password === this.passwordConfirmation)
    },
    validateMemberData() {
      if (!this.localMemberToEdit.full_name) {
        this.modalError = 'Member\'s name is required'
        return false
      }

      if (!this.validateEmail()) {
        this.modalError = 'Please, enter valid member\'s email'
        return false
      }

      if (this.password && !this.validatePassword()) {
        this.modalError = 'The passwords should match and be at least 8 characters long'
        return false
      }

      return true
    },
    async handleMemberUpdate() {
      this.modalError = ''

      if (!this.validateMemberData()) {
        return
      }
      
      this.isModalLoading = true

      const memberUpdate = {
        member_id: this.localMemberToEdit.id,
        member_update: {
          full_name: this.localMemberToEdit.full_name,
          email: this.localMemberToEdit.email,
          represented_company: this.localMemberToEdit.represented_company,
          address: this.localMemberToEdit.address,
          phone: this.localMemberToEdit.phone,
          password: this.password,
        },
      }

      try {
        await this.updateMember(memberUpdate)
        this.$emit('hideModal')
      } catch (error) {
        this.modalError = error.response.data.detail
      } finally {
        this.isModalLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

:deep(.action-modal) {
  overflow-y: scroll !important;
}
  
.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  .common-input-field-container {
    margin-top: 20px;
  }

  .field-label {
    color: $fontColorBlack;
    font-size: $fontSizeMinified;
  }

  .input-field {
    width: 100%;
    height: 44px;
    margin-top: 8px;
    padding: 0 6px;
    font-size: $fontSizeStandart;
    border: 1px solid $grayBorderColor;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
  }

  .input-container {
    width: 100%;
    height: 68px;
    margin-top: 20px;
  }

  .password-container {
    position: relative;

    .password-visibility-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: calc(100% - 34px);
      right: 12px;
      height: 24px;
      background-size: 24px;
      background-position: center;
      cursor: pointer;
    }

    .password-invisible-icon {
      background-image: url('../../../../assets/icons/visibility-off-icon.svg');
    }

    .password-visible-icon {
      background-image: url('../../../../assets/icons/visibility-on-icon.svg');
    }
  }

  .action-button {
    margin-top: 27px;
  }
}

@media (max-width: 420px) {
  .action-button {
    width: 275px !important;
  }
}
</style>