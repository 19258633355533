<template>
  <div class="table-card-row">
    <div class="member-row-text-field">
      {{ user.full_name }}
    </div>

    <div class="member-row-text-field-end">
      <div v-if="loading" class="spinner-container">
        <Spinner :width="'4px'" :height="'4px'" :containerHeight="'44px'" />
      </div>
      <div v-else>
        <ActionButton
          v-if="!user.isAttached"
          :width="'80px'"
          :height="'40px'"
          :text="'Attach'"
          class="attach-button"
          @click="attach"
        />
        <p v-else class="attached">Attached</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ActionButton from '@/components/common/ActionButton.vue'
import Spinner from '../Spinner.vue'

export default {
  props: ['user', 'managerId'],
  components: {
    ActionButton,
    Spinner,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions(['assignUserToManager']),
    async attach() {
      const payload = { managerId: this.managerId, id: this.user.id }
      this.loading = true

      try {
        await this.assignUserToManager(payload)
        this.user.isAttached = true
        this.loading = false
      } catch (error) {
        this.errorMessage = error
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.table-card-row {
  width: calc(100% - 40px);
  height: 64px;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;

  .spinner-container {
    display: flex;
    justify-content: center;
    margin-right: 14px;
  }
  .attached {
    padding-right: 6px;
  }
  .member-row-text-field {
    width: 370px;
    min-width: 370px;
    height: 20px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .member-row-text-field-end {
    min-width: 146px;
    display: flex;
    justify-content: end;
  }

  .attach-button {
    margin-top: 0;
  }
}
</style>
