<template>
  <ActionModal
    v-if="isModalShown"
    :width="'480px'"
    :height="'260px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
  <div class="modal-header">
    <div class="modal-title">Invite Member</div>
    <div class="close-modal-btn" @click="$emit('hideModal')"></div>
  </div>
  <div class="modal-content">
    <div class="modal-error">{{modalError}}</div>
    <CommonInputField
      :width="'100%'"
      :height="'68px'"
      :inputHeight="'44px'"
      :lable="'Email' "
      :placeholder="'Enter Email'"
      :value=newInviteEmail
      @updateValue="$emit('setNewEmailInvite',$event)"
    />
    <ActionButton
      :width="'400px'"
      :height="'54px'"
      :text="'Send Invite'"
      @click="$emit('inviteMembers')"
    />
  </div>
  </ActionModal>
</template>

<script>
import ActionModal from '../../../common/ActionModal.vue'
import ActionButton from '../../../common/ActionButton.vue'
import CommonInputField from '../../../common/CommonInputField.vue'
export default {
  props: [
    'isModalShown',
    'isModalLoading',
    'modalError',
    'newInviteEmail'
  ],
  components: {
    ActionButton,
    ActionModal,
    CommonInputField,
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.modal-content {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media (max-width: 420px) {
  .action-button {
    width: 275px !important;
  }
}
</style>
