<template>
  <ActionModal
    v-if="isModalShown"
    :width="'480px'"
    :height="'200px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
  <div class="modal-header">
    <div class="modal-title">Exporting members...</div>
  </div>
  <div class="modal-content">
    <div
      v-if="modalMessage"
      class="modal-message"
    >
      {{modalMessage}}
    </div>
    <div
      v-if="!modalMessage"
      class="modal-message modal-error"
    >
      {{modalError}}
    </div>
    <ActionButton
      v-if="!isModalLoading"
      :height="'44px'"
      :text="'OK'"
      @click="$emit('hideModal')"
    />
  </div>
  </ActionModal>
</template>

<script>
import ActionModal from './ActionModal.vue'
import ActionButton from './ActionButton.vue'

export default {
  props: [
    'isModalShown',
    'isModalLoading',
    'importedFileName',
    'modalMessage',
    'modalError',
  ],
  components: {
    ActionModal,
    ActionButton,
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }
  
  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-message {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $appActionColor;
  font-size: $fontSizeMinified;
}

.modal-error {
  color: $redWarningColor;
}

.modal-content {
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 25px;

  .modal-subtitle {
    width: 100%;
    color: $fontColorGray;
    font-size: $fontSizeMinified;
  }

  .csv-input-label {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;

    .input-title {
      width: 74px;
    }

    .csv-import-icon {
      width: 24px;
      height: 24px;
      margin-left: 5px;
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/import-icon.svg');
    }

    #csv-input {
      display: none;
    }

    .file-name-container {
      width: calc(100% - 108px);
      max-width: 100%;
      margin-left: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .action-button {
    margin-top: auto;
    width: 100%;
  }
}

@media (max-width: 420px) {
  .action-button {
    width: 100px !important;
  }
}
</style>