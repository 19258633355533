<template>
  <ActionModal
    v-if="isModalShown"
    :width="'480px'"
    :height="'275px'"
    :isModalShown="isModalShown"
    :isLoading="isModalLoading"
  >
    <div class="modal-header">
      <div class="modal-title">Please set expiration date</div>
      <div class="close-modal-btn" @click="closeModal"></div>
    </div>
    <div class="option-granted-body">
      <div class="option-date-block">
        <div class="unlimited-option">
          <div
            class="radio-btn"
            :class="{'active-radio-btn':chooseOption === options.UNLIMITED}"
            @click="setOption(options.UNLIMITED)"
          >
          </div>
          <span>Unlimited</span>
        </div>
        <div class="choose-date-option">
          <div
            class="radio-btn"
            :class="{'active-radio-btn':chooseOption === options.DATE}"
            @click="setOption(options.DATE)"
          >
          </div>
          <span>Choose date</span>
        </div>
      </div>
      <div class="date-picker-container">
        <VueDatePicker
          v-model="datePickerDate"
          :disabled="chooseOption !== 'Date'"
          :enable-time-picker="false"
          :min-date="minDate"
          :clearable="false"
          utc
          teleport-center
          calendar-cell-class-name="dp-custom-cell"
        >
        </VueDatePicker>
      </div>
    </div>
    <div class="btn-container">
      <ActionButton
        :width="'100%'"
        :height="'44px'"
        :text="'Ok'"
        @click="setGratedUntilDate"
      />
    </div>
  </ActionModal>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css'

import VueDatePicker from '@vuepic/vue-datepicker';
import ActionButton from "@/components/common/ActionButton.vue";
import ActionModal from "@/components/common/ActionModal.vue";
import {mapActions} from "vuex";

const options = {
  UNLIMITED: 'Unlimited',
  DATE: 'Date'
};
export default {
  name: "GrantedConfigActionModal",
  props: ["isModalShown","memberId","currentMemberPeriodEnd"],
  components: {ActionModal, ActionButton,VueDatePicker},
  data() {
    return {
      date: '',
      isModalLoading: false,
      minDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toUTCString(),
      chooseOption: options.UNLIMITED,
      options: options
    }
  },
  computed: {
    datePickerDate: {
      get() {
        return this.date;
      },
      set(val) {
        this.date = new Date(val);
      }
    }
  },
  watch: {
    chooseOption(newValue,oldValue) {
      if (newValue === options.UNLIMITED) {
        this.date = ''
      } else {
        this.date = this.currentMemberPeriodEnd
          ? new Date(this.currentMemberPeriodEnd*1000)
          : this.getDateInNextMonth()
      }
    }
  },
  methods: {
    ...mapActions([
      'grantedSubscription',
    ]),
    resetDate() {
      this.chooseOption = options.UNLIMITED
      this.date = ''
      this.datePickerDate = ''
    },
    async closeModal() {
      await this.resetDate()
      this.$emit('hideModal')
    },
    getDateInNextMonth() {
      const currentDate = new Date();
      const currentDay = currentDate.getUTCDate();

      let nextMonth = currentDate.getUTCMonth() + 1;
      let nextYear = currentDate.getUTCFullYear();

      if (nextMonth > 11) {
        nextYear++;
        nextMonth = 0;
      }

      const lastDayOfCurrentMonth = new Date(Date.UTC(nextYear, nextMonth, 0)).getUTCDate();

      if (currentDay > lastDayOfCurrentMonth) {
        const lastDayOfNextMonth = new Date(Date.UTC(nextYear, nextMonth + 1, 0)).getUTCDate();
        return new Date(Date.UTC(nextYear, nextMonth, lastDayOfNextMonth));
      } else {
        return new Date(Date.UTC(nextYear, nextMonth, currentDay));
      }
    },
    async setGratedUntilDate() {
      try {
        this.isModalLoading = true;

        let dateUnixTimestamp;
        if(this.datePickerDate instanceof Date) {
          dateUnixTimestamp = Math.floor(this.datePickerDate.getTime() / 1000);
        }

        const payload = { memberId: this.memberId };

        if (this.chooseOption === options.DATE) {
          payload.dateExpire = dateUnixTimestamp;
        }

        await this.grantedSubscription(payload);

        this.$emit('hideModal');
        await this.resetDate()
        this.$emit('completeGranted');
      } catch (e) {
        console.log(e);
      } finally {
        this.isModalLoading = false;
      }
    },
    setOption(option) {
      this.chooseOption = option;
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title {
    font-size: $fontSizeModalTitle;
  }

  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}
.option-granted-body {
  width: calc(100% - 80px);
  margin-top: 24px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  height: 100%;

  .option-date-block {
    display: flex;
    gap: 30px;

    .unlimited-option {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .choose-date-option {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .date-picker-container {
    display: flex;
    :deep(.dp__theme_light) {
      --dp-primary-color: #69C9A0 !important; /* Primary color for the calendar picker button */
    }
  }

  .radio-btn {
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 10px;
    justify-content: center;
    cursor: pointer;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/radio-btn-off-icon.svg');
  }

  .active-radio-btn {
    background-image: url('../../../../assets/icons/radio-btn-on-icon.svg');
  }
}
.modal-error {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $redWarningColor;
  font-size: $fontSizeMinified;
}

.btn-container {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 30px;
}

@media (max-width: 420px) {
  .action-button {
    width: 100px !important;
  }
}
</style>
